:root {
  --green1: #04e9ac;
  --green2: #7cfcda;
  --green3: #10845c;
  --gray1: #7b7c7c;
  --gray2: #c6ccca;
  --gray3: #444444;
}

body {
  background-color: var(--gray3);
}

.terminal {
  color: var(--green1);
  margin-bottom: 20px;
  width: 100%;
  max-width: 100%;
}

.terminal-input,
.terminal-input:focus {
  border: none;
  outline: none;
  caret-color: var(--green1);
  background-color: transparent;
  color: var(--green1);
  font-family: monospace;
  width: 100%;
  max-width: 100%;
}
.cursor {
  height: 1rem;
  width: 10px;
  background-color: var(--gray1);
  display: inline-block;
}
